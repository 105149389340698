import React from 'react';
import { Route, Routes } from 'react-router';
import { NotFoundPage } from './Common';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { ProvideAuth } from '../hooks';
import {LicenseInfo} from '@mui/x-license-pro';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import WorkerTravelDetailsPage from './PushNotification/WorkerTravelDetailsPage';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || 'not known';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const StyledApp = styled(Box)`
    background-image: url(/images/brb_logo_vertical_white.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#42C4AB',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledApp>
                <BrowserRouter>
                    <ProvideAuth>
                        <ProvideSnackBar>
                            <Routes>
                                <Route path='/' element={<WorkerTravelDetailsPage />} />
                                <Route path='/*' element={<NotFoundPage />} />
                            </Routes>
                        </ProvideSnackBar>
                    </ProvideAuth>
                </BrowserRouter>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
