import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';

const FIREBASE_API_ENDPOINT = process.env.REACT_APP_FIREBASE_API_URL;

type SendNotificationProps = {
    registrationTokens: string[];
    setRegistrationTokens: (registrationTokens: string[]) => void;
};

export default function SendNotification({ registrationTokens, setRegistrationTokens }: SendNotificationProps) {
    const { user } = useAuth();
    const { showSuccess, showError, showResponseError } = useProvideSnackBar();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const validateNotificationData = () => {
        if (title === '') {
            showError('Hiányzó cím');
            return false;
        }
        if (body === '') {
            showError('Hiányzó üzenet');
            return false;
        }
        if (registrationTokens.length === 0) {
            showError('Hiányzó címzett');
            return false;
        }
        return true;
    };

    const fetchSendNotification = async () => {
        if (!validateNotificationData()) {
            return;
        }
        try {
            const response = await fetch(`${FIREBASE_API_ENDPOINT}/v1/notification/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    registrationTokens: registrationTokens.map(token => token.split(' ')[1]),
                    body: body,
                    title: title,
                }),
            });
            if (!response.ok) {
                showResponseError(response);
            } else {
                showSuccess('Értesítés sikeresen elküldve.');
                setTitle('');
                setBody('');
                setRegistrationTokens([]);
            }
        } catch (error) {
            showError('Értesítés küldése sikertelen.');
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <TextField
                            id='title'
                            label='Cím'
                            variant='outlined'
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id='body'
                            label='Üzenet'
                            variant='outlined'
                            value={body}
                            onChange={e => setBody(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={2} display='flex' alignItems='end' justifyContent='center'>
                <Button variant='contained' color='primary' sx={{ maxHeight: '65px', minHeight: '65px' }} onClick={fetchSendNotification} fullWidth>
                    Küldés
                </Button>
            </Grid>
        </Grid>
    );
}
